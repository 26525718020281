import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { Space, Table, Tag } from 'antd';

import { User } from '../../store/api/apiTypes';
import { list as userslist, getUsersListState } from '../../store/actions/users';
import { test } from '../../store/actions/test';

import Seo from '../../components/Seo';
import LayoutHeader from '../../components/LayoutHeader';
import ListTitle from '../../components/ListTitle';
import useQueryParams from '../../hooks/queryParams';
import { getFullName } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import { useActions } from '../../hooks';

const rowKey = (item: User) => `${item.id}`;
const columns: Array<ColumnProps<User>> = [
    {
        key: 'name',
        title: 'Full name',
        render: (item) => (
            <Link to={getRoute(RoutePathName.usersDetails, { userId: item.id })}>
                {getFullName(item.firstName, item.lastName)}
            </Link>
        ),
    },
    {
        dataIndex: 'email',
        title: 'Email',
    },
    {
        dataIndex: ['role', 'name'],
        title: 'Role',
        render: (value) => {
            return <Tag>{value}</Tag>;
        },
    },
];

const UsersList: FC = () => {
    const [loadUsers] = useActions([userslist.trigger, test.actions.yolo]);
    const usersListState = useSelector(getUsersListState);
    const [queryParams, setQueryParams] = useQueryParams('users-list');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 1 : 1;
    const onTableChange: TableProps<User>['onChange'] = (pagination) => {
        setQueryParams({
            page: pagination.current ?? 1 - 1,
        });
    };

    useEffect(() => {
        loadUsers({
            page: page - 1,
            pageSize: 1,
        });
    }, [loadUsers, page]);

    return (
        <>
            <Seo title="Utilisateurs" />
            <LayoutHeader>
                <ListTitle count={usersListState.data?.totalCount}>Utilisateurs</ListTitle>
            </LayoutHeader>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Table<User>
                    rowKey={rowKey}
                    columns={columns}
                    loading={usersListState.loading}
                    dataSource={usersListState.data?.items}
                    pagination={{
                        total: usersListState.data?.totalCount,
                        current: page,
                        pageSize: 1,
                    }}
                    onChange={onTableChange}
                />
            </Space>
        </>
    );
};

export default UsersList;
