import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Event, ListResponse } from '../api/apiTypes';

// Controlers
import { EventIdPayload, list as listApiCall, details as detailsApiCall, EventListPayload } from '../api/event';

// States
export interface EventState {
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Event;
        };
    };
    list: RequestState<ListResponse<Event>>;
}

const initialState: EventState = {
    details: {
        error: '',
        data: {},
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
};

export const details = new EzeeAsyncAction<EventState['details'], EventIdPayload, Event>(
    'event/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const list = new EzeeAsyncAction<EventState['list'], EventListPayload, Event>(
    'events/list',
    initialState.list,
    requestReducer<EventState['list'], Event>(initialState.list)
);

// Reducer
export const eventReducer = combineReducers<EventState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* eventSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getEventState = (state: MainReducerState) => state.event;
export const getEventListState = (state: MainReducerState) => state.event.list;
export const getEventDetailsState = (state: MainReducerState) => state.event.details;
/* export const geteventStateById = (id: Notification['id']) => (state: MainReducerState) => ({
    loading: state.event.details.loading,
    error: state.event.details.error,
    data: state.event.details.data[id],
}); */
