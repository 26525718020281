import { useEffect, useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import useSessionStorage from './sessionStorage';
import { usePrevious } from '.';

export default function useQueryParams(key: string): [URLSearchParams, (params: { [key: string]: any }) => void] {
    const history = useHistory();
    const location = useLocation<{ isBack?: boolean }>();
    const previousLocation = usePrevious(location.pathname);
    const isBack = location.state?.isBack;
    const [storedParams, setStoredParams] = useSessionStorage(key, {});
    const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const setParams = useCallback(
        (params: { [key: string]: any }) => {
            for (const name in params) {
                if (Object.prototype.hasOwnProperty.call(params, name)) {
                    if (params[name] === undefined) {
                        urlSearchParams.delete(name);
                    } else if (Array.isArray(params[name])) {
                        params[name].forEach((value: any) => {
                            urlSearchParams.append(name, value);
                        });
                    } else {
                        urlSearchParams.set(name, params[name]);
                    }
                }
            }

            history.push({
                pathname: location.pathname,
                search: urlSearchParams.toString(),
            });

            setStoredParams({
                ...storedParams,
                ...params,
            });
        },
        [history, location.pathname, setStoredParams, urlSearchParams, storedParams]
    );

    useEffect(() => {
        if (previousLocation !== location.pathname && isBack) {
            setParams(storedParams);
        }
    }, [previousLocation, location.pathname, isBack, setParams, storedParams]);

    return [urlSearchParams, setParams];
}
