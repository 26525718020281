import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { Device } from './apiTypes';

// Controller Interface

export interface DeviceIdPayload {
    id: Device['id'];
}

export type DeviceListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: DeviceListPayload = {}) => {
    return await api.get(`/devices`, { params: payload });
};
