import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { LogsPayload, logs as logsApiCall } from '../api/logs';
import { RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// State

export type LogsState = RequestState;

const initialState: LogsState = {
    loading: false,
};

// Actions/Reducers

export const logs = new EzeeAsyncAction<LogsState, LogsPayload>(
    'logs',
    initialState,
    requestReducer<LogsState>(initialState)
);

// Reducer

export const logsReducer = logs.reducer;

// Saga

export function* logsSaga() {
    yield takeLatest(logs.type.trigger, simpleAsyncSaga(logsApiCall, logs));
}
