// Types of the backend API

export enum SupportedLanguage {
    fr = 'fr',
    en = 'en',
    // es = 'es',
    // du = 'du',
    // it = 'it',
    // de = 'de',
    // ca = 'ca',
    // po = 'po',
    // ru = 'ru',
    // pt = 'pt',
    // tr = 'tr',
    // us = 'us',
    // da = 'da',
    // hu = 'hu',
    // zh = 'zh',
    // ko = 'ko',
    // sv = 'sv',
    // cs = 'cs',
    // no = 'no',
    // br = 'br',
    // ja = 'ja',
}

export enum DeviceType {
    ios = 'ios',
    android = 'android',
    web = 'web',
    koala = 'koala',
    api = 'api',
    nothing = '',
}

export enum NotificationType {
    text = 'text',
}

export enum NotificationChannel {
    pull = 'pull',
    email = 'email',
    mobilePush = 'mobilePush',
    webhook = 'webhook',
}

export enum NotificationStatus {
    pending = 'pending',
    sent = 'sent',
    received = 'received',
    opened = 'opened',
    failed = 'failed',
    canceled = 'canceled',
}

export interface Organization {
    id: string;
    name: string;
    applications?: Application[];
}
export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    organization?: Organization;
}

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

export interface Application {
    id: string;
    name: string;
    organization?: Organization;
    applicationClients?: ApplicationClient[];
}

export interface ApplicationClient {
    id: string;
    name: string;
    apiKey: string;
    isPrivate: boolean;
    reference: string;
    type: DeviceType;
    options: {
        isBackoffice?: boolean;
    };
    iosOptions?: {
        authentificationPush: string;
        keyId: string;
        teamId: string;
        bundleId: string;
        stagingServer: boolean;
    };
    androidOptions?: {
        certificatPush: string;
        bundleId: string;
    };
    webhookUrl?: string;
    headers?: {
        [key: string]: string;
    };
    application?: Application;
    organization?: Organization;
}

export interface Context {
    firstName: string;
    user: string;
    organization: string;
    group: string[];
    program: string;
    score: Int16Array;
}
export interface Recipient {
    id: string;
    reference: string;
    organization: Organization;
    application: Application;
    context: Context;
}
export interface Notification {
    id: string;
    organization: Organization;
    recipient: Recipient;
    messageTrigger?: MessageTrigger;
    event?: Event;
    device?: Device;
    type?: NotificationType;
    channel?: NotificationChannel;
    payload: PayLoad;
    reference: string;
    status?: NotificationStatus;
    sendAt?: string;
    failedMessage?: string;
}

export interface Event {
    id: string;
    recipient?: Recipient;
    slug: string;
    application?: Application;
    messageTriggers?: MessageTrigger[];
    date: Date;
    scheduled?: Date;
    processed: boolean;
    scope?: MessageTriggerScope;
    context?: Context;
}
export interface MessageTrigger {
    id: string;
    slug: string;
    event: MessageTriggerEvent;
    name: Translation;
    channels: NotificationChannel[];
    template?: MessageTemplate;
    application?: Application;
    isEnabled: boolean;
    scope?: MessageTriggerScope;
    context?: unknown;
    meta?: unknown;
}

export interface MessageTriggerScope {
    isProfileCompleted?: boolean;
}

export interface MessageTemplate {
    id: string;
    slug: string;
    title: Translation;
    text: Translation;
    variables?: string[];
    application?: Application;
    context?: unknown;
}

export interface MessageTriggerEvent {
    slug: string | string[];
    count?: number;
    reminder?: boolean;
    scheduled?: number;
    notSince?: number;
}

export type Translation = {
    [key in SupportedLanguage]?: string;
};
export interface Device {
    id: string;
    deviceId: string;
    deviceType: DeviceType;
    applicationClient?: ApplicationClient;
    organization?: Organization;
    recipient?: Recipient;
    isRetired?: boolean;
}

export interface PayLoad {
    title: TitleMap;
    text: TextMap;
    context: Context;
}

export interface TitleMap {
    fr: string;
}

export interface TextMap {
    fr: string;
}
