import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { User, ListResponse } from '../api/apiTypes';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

import { UserDetailsPayload, UserListPayload, details as detailsApiCall, list as listApiCall } from '../api/users';

// State

export interface UsersState {
    list: RequestState<ListResponse<User>>;
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: User;
        };
    };
}

const initialState: UsersState = {
    details: {
        data: {},
        loading: false,
    },
    list: {
        loading: false,
    },
};

// Actions/Reducers

export const details = new EzeeAsyncAction<UsersState['details'], UserDetailsPayload, User>(
    'users/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const list = new EzeeAsyncAction<UsersState['list'], UserListPayload, ListResponse<User>>(
    'users/list',
    initialState.list,
    requestReducer<UsersState['list'], ListResponse<User>>(initialState.list)
);

// Reducer

export const usersReducer = combineReducers<UsersState>({
    details: details.reducer,
    list: list.reducer,
});

// Saga

export function* usersSaga() {
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
}

// Store helpers

export const getUsersListState = (state: MainReducerState) => state.users.list;
export type UserDetailsState = RequestState<User>;

export const getUserStateById = (id: User['id']) => (state: MainReducerState) => ({
    loading: state.users.details.loading,
    error: state.users.details.error,
    data: state.users.details.data[id],
});
