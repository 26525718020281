import React, { FC, useEffect } from 'react';

// Antd lib
import Table, { ColumnProps, TableProps } from 'antd/lib/table';
import { Col, Row } from 'antd';

// Store
import { useSelector } from 'react-redux';

import { list as listAction, getRecipientsListState } from '../../store/actions/recipients';

// Api Types
import { Recipient } from '../../store/api/apiTypes';

// Helpers
import { useActions } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';

// Components
import Seo from '../../components/Seo';
import ListTitle from '../../components/ListTitle';
import LayoutHeader from '../../components/LayoutHeader';

// Style
import '../../assets/styles/Notifications.less';
import useQueryParams from '../../hooks/queryParams';
import { Link } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-redeclare
const RecipientsList: FC = () => {
    const pageSize = 20;
    const [loadApplications] = useActions([listAction.trigger]);
    const [queryParams, setQueryParams] = useQueryParams('recipients');
    const page = queryParams.get('page') !== null ? parseInt(queryParams.get('page')!, 10) || 1 : 1;
    const onTableChange: TableProps<Recipient>['onChange'] = (pagination) => {
        setQueryParams({
            page: pagination.current ?? 1 - 1,
        });
    };

    const listRecipientsState = useSelector(getRecipientsListState);
    // const detailsRecipientsState = useSelector(getRecipientsDetailsState);

    const rowKey = (item: Recipient) => `${item.id}`;

    const columns: Array<ColumnProps<Recipient>> = [
        {
            title: 'Recipients ID',
            render: (record) => (
                <Link to={getRoute(RoutePathName.recipientsDetails, { id: record.id })}>{record.id}</Link>
            ),
        },
        {
            title: "Nom de l'organisation",
            key: 'organizationName',
            render: (record) => {
                return record.organization.name;
            },
        },
    ];

    // Laod application list in startup
    useEffect(() => {
        loadApplications({
            page: page - 1,
            pageSize: pageSize,
        });
    }, [loadApplications, page]);

    return (
        <>
            <Seo title="Recipients" />
            <LayoutHeader>
                <Row>
                    <Col span={12}>
                        <ListTitle count={listRecipientsState.data?.totalCount}>Recipients</ListTitle>
                    </Col>
                </Row>
            </LayoutHeader>

            <Table<Recipient>
                className="notifications-list"
                columns={columns}
                rowKey={rowKey}
                loading={listRecipientsState.loading}
                dataSource={listRecipientsState.data?.items}
                pagination={{
                    total: listRecipientsState.data?.totalCount,
                    current: page,
                    pageSize: pageSize,
                }}
                onChange={onTableChange}
            />
        </>
    );
};

export default RecipientsList;
