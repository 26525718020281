import React, { FC, useEffect, useState } from 'react';

// Antd lib
import Table, { ColumnProps /*, TableProps */ } from 'antd/lib/table';
import { Button, Col, message, Popconfirm, Row } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

// Store
import { useSelector } from 'react-redux';

import {
    list as listAction,
    remove as removeAction,
    getOrganizationsListState,
    getOrganizationsCreateState,
    getOrganizationsRemoveState,
    getOrganizationsUpdateState,
    getOrganizationsDetailsState,
} from '../../store/actions/organizations';

// Api Types
import { Organization } from '../../store/api/apiTypes';

// Helpers
import { useActions, usePrevious } from '../../hooks';

// Components
import Seo from '../../components/Seo';
import ListTitle from '../../components/ListTitle';
import LayoutHeader from '../../components/LayoutHeader';
import OrganizationCreateModal from './components/OrganizationCreateModal';
import OrganizationUpdateModal from './components/OrganizationUpdateModal';

// Style
import '../../assets/styles/Organizations.less';

const Organizations: FC = () => {
    const [loadOrganizations, removeOrganizations] = useActions([listAction.trigger, removeAction.trigger]);
    const listOrganizationsState = useSelector(getOrganizationsListState);
    const createOrganizationsState = useSelector(getOrganizationsCreateState);
    const updateOrganizationsState = useSelector(getOrganizationsUpdateState);
    const removeOrganizationsState = useSelector(getOrganizationsRemoveState);
    const detailsOrganizationsState = useSelector(getOrganizationsDetailsState);
    const rowKey = (item: Organization) => `${item.id}`;

    const previous = usePrevious({
        listOrganizationsState,
        createOrganizationsState,
        updateOrganizationsState,
        removeOrganizationsState,
        detailsOrganizationsState,
    });

    const [isVisibleOrganizationCreateModal, setIsVisibleOrganizationCreateModal] = useState<boolean>(false);
    const [isVisibleOrganizationUpdateModal, setIsVisibleOrganizationUpdateModal] = useState<boolean>(false);
    const [organizationId, setOrganizationId] = useState<Organization['id']>('');

    const createOrganizationModalVisible = () => {
        setIsVisibleOrganizationCreateModal(true);
    };

    const onClickRemoveOrganizationButton = (id: Organization['id']) => {
        removeOrganizations({ id });
    };

    const onClickUpdateOrganizationButton = (id: Organization['id']) => {
        setOrganizationId(id);
        setIsVisibleOrganizationUpdateModal(true);
    };

    const columns: Array<ColumnProps<Organization>> = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '18%',
        },
        {
            title: "Nom de l'organisation",
            dataIndex: 'name',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: '15%',
            render: (id: string) => {
                // const isRemoveButtonDisabled = record.applicationClients?.length === 0 ? false : true;
                const removeButton = (
                    <Popconfirm
                        placement="topLeft"
                        title={'Êtes-vous sûr de supprimer cette organisation ?'}
                        onConfirm={onClickRemoveOrganizationButton.bind(null, id)}
                        okText="Oui"
                        cancelText="Non"
                        // disabled={isRemoveButtonDisabled}
                    >
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            // disabled={isRemoveButtonDisabled}
                        />
                    </Popconfirm>
                );
                return (
                    <>
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={onClickUpdateOrganizationButton.bind(null, id)}
                        />

                        {removeButton}
                    </>
                );
            },
        },
    ];

    // Modals Actions
    const hideComponents = () => {
        setIsVisibleOrganizationCreateModal(false);
        setIsVisibleOrganizationUpdateModal(false);
    };

    // Load organization list when create organization
    useEffect(() => {
        if (previous?.createOrganizationsState.loading && !createOrganizationsState.loading) {
            if (createOrganizationsState.success) {
                loadOrganizations();
            }
        }
    }, [previous, createOrganizationsState, loadOrganizations]);

    // Load organization list when update organization
    useEffect(() => {
        if (previous?.updateOrganizationsState.loading && !updateOrganizationsState.loading) {
            if (updateOrganizationsState.success) {
                loadOrganizations();
            }
        }
    }, [previous, updateOrganizationsState, loadOrganizations]);

    // Load organization list when remove organization
    useEffect(() => {
        if (previous?.removeOrganizationsState.loading && !removeOrganizationsState.loading) {
            if (removeOrganizationsState.success) {
                message.success("L'organisation supprimée");
            }
            loadOrganizations();
        }
    }, [previous, removeOrganizationsState, loadOrganizations]);

    // Laod organization list in startup
    useEffect(() => {
        loadOrganizations();
    }, [loadOrganizations]);

    return (
        <>
            <Seo title="Organizations" />
            <LayoutHeader>
                <Row>
                    <Col span={12}>
                        <ListTitle>Organizations</ListTitle>
                    </Col>
                    <Col span={12}>
                        <Button
                            className="header-button"
                            size="large"
                            type="primary"
                            onClick={createOrganizationModalVisible}
                        >
                            Ajouter une organisation
                        </Button>
                    </Col>
                </Row>
            </LayoutHeader>

            <Table<Organization>
                className="organizations-list"
                columns={columns}
                rowKey={rowKey}
                loading={listOrganizationsState.loading}
                dataSource={listOrganizationsState.data?.items}
                pagination={false}
            />

            <OrganizationCreateModal
                isComponentVisible={isVisibleOrganizationCreateModal}
                hideComponent={hideComponents}
            />

            <OrganizationUpdateModal
                organizationId={organizationId}
                isComponentVisible={isVisibleOrganizationUpdateModal}
                hideComponent={hideComponents}
            />
        </>
    );
};

export default Organizations;
