import React, { FC, useEffect, useState } from 'react';

// Antd lib
import { Form, Input, message, Modal } from 'antd';

import { FormProps } from 'antd/lib/form';

// Store
import { useSelector } from 'react-redux';
import {
    update as organizationUpdateApiCall,
    details as organizationDetailsApiCall,
    getOrganizationsUpdateState,
    getOrganizationsDetailsState,
} from '../../../store/actions/organizations';

// Api Types
import { Organization } from '../../../store/api/apiTypes';

// Helpers
import { useActions, usePrevious } from '../../../hooks';

// Style
import '../../../assets/styles/ApplicationsModal.less';

interface OrganizationUpdateModalProps {
    organizationId: Organization['id'];

    hideComponent: () => void;
    isComponentVisible: boolean;
}

const OrganizationUpdateModal: FC<OrganizationUpdateModalProps> = ({
    organizationId,

    hideComponent,
    isComponentVisible,
}) => {
    const [updateOrganizations, detailsOrganizations] = useActions([
        organizationUpdateApiCall.trigger,
        organizationDetailsApiCall.trigger,
    ]);
    const updateOrganizationsState = useSelector(getOrganizationsUpdateState);
    const detailsOrganizationsState = useSelector(getOrganizationsDetailsState);
    const [updateForm] = Form.useForm();

    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(isComponentVisible);

    const previous = usePrevious({
        updateOrganizationsState,
        detailsOrganizationsState,
    });

    const onOk = () => {
        updateForm.submit();
        setIsVisibleModal(false);
        hideComponent();
    };

    const onCancel = () => {
        setIsVisibleModal(false);
        hideComponent();
    };

    const onSubmit: FormProps['onFinish'] = (values) => {
        updateOrganizations({
            id: organizationId,
            body: {
                name: values.name,
            },
        });
        setIsVisibleModal(false);
        hideComponent();
    };

    // Use Effect action
    // Application Update State
    useEffect(() => {
        if (previous?.updateOrganizationsState.loading && !updateOrganizationsState.loading) {
            if (updateOrganizationsState.success) {
                message.success("L'organisation a été mis à jour");
            }
        }
    }, [previous, updateOrganizationsState]);

    // Application Get Details State
    useEffect(() => {
        if (previous?.detailsOrganizationsState.loading && !detailsOrganizationsState.loading) {
            if (detailsOrganizationsState.success) {
                updateForm.setFieldsValue({
                    name: detailsOrganizationsState.data?.name ?? '',
                });
            }
        }
    }, [previous, updateForm, detailsOrganizationsState]);

    // Load application detail modal in startup
    useEffect(() => {
        if (organizationId) {
            detailsOrganizations({
                id: organizationId,
            });
            setIsVisibleModal(isComponentVisible);
        }
    }, [organizationId, isComponentVisible, setIsVisibleModal, detailsOrganizations]);

    return (
        <>
            <Modal
                title="Modifier une organisation"
                centered
                visible={isVisibleModal}
                okText="Mettre à jour"
                cancelText="Annuler"
                onOk={onOk}
                onCancel={onCancel}
                destroyOnClose={true}
            >
                <Form name="Modification" form={updateForm} onFinish={onSubmit}>
                    <Form.Item
                        label="Nom de l'organisation"
                        name="name"
                        rules={[{ required: true, message: "Veuillez rentrer le nom de l'organisation !" }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default OrganizationUpdateModal;
