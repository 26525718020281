import React, { FC, useEffect, useState } from 'react';

// Antd lib
import { Form, Input, message, Modal } from 'antd';

import { FormProps } from 'antd/lib/form';

// Store
import { useSelector } from 'react-redux';
import {
    update as applicationUpdateApiCall,
    details as applicationDetailsApiCall,
    getApplicationsUpdateState,
    getApplicationsDetailsState,
} from '../../../store/actions/applications';

// Api Types
import { Application } from '../../../store/api/apiTypes';

// Helpers
import { useActions, usePrevious } from '../../../hooks';

// Style
import '../../../assets/styles/ApplicationsModal.less';

interface ApplicationUpdateModalProps {
    applicationId: Application['id'];

    hideComponent: () => void;
    isComponentVisible: boolean;
}

const ApplicationUpdateModal: FC<ApplicationUpdateModalProps> = ({
    applicationId,

    hideComponent,
    isComponentVisible,
}) => {
    const [updateApplications, detailsApplications] = useActions([
        applicationUpdateApiCall.trigger,
        applicationDetailsApiCall.trigger,
    ]);
    const updateApplicationsState = useSelector(getApplicationsUpdateState);
    const detailsApplicationsState = useSelector(getApplicationsDetailsState);
    const [updateForm] = Form.useForm();

    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(isComponentVisible);

    const previous = usePrevious({
        updateApplicationsState,
        detailsApplicationsState,
    });

    const onOk = () => {
        updateForm.submit();
    };

    const onCancel = () => {
        setIsVisibleModal(false);
        hideComponent();
    };

    const onSubmit: FormProps['onFinish'] = (values) => {
        updateApplications({
            id: applicationId,
            body: {
                name: values.name,
            },
        });
        setIsVisibleModal(false);
        hideComponent();
    };

    // Use Effect action
    // Application Update State
    useEffect(() => {
        if (previous?.updateApplicationsState.loading && !updateApplicationsState.loading) {
            if (updateApplicationsState.success) {
                message.success("L'application a été mis à jour");
            }
        }
    }, [previous, updateApplicationsState]);

    // Application Get Details State
    useEffect(() => {
        if (previous?.detailsApplicationsState.loading && !detailsApplicationsState.loading) {
            if (detailsApplicationsState.success) {
                updateForm.setFieldsValue({
                    name: detailsApplicationsState.data?.name ?? '',
                });
            }
        }
    }, [previous, updateForm, detailsApplicationsState]);

    // Load application detail modal in startup
    useEffect(() => {
        if (applicationId) {
            detailsApplications({
                id: applicationId,
            });
            setIsVisibleModal(isComponentVisible);
        }
    }, [applicationId, isComponentVisible, setIsVisibleModal, detailsApplications]);

    return (
        <>
            <Modal
                title="Modifier une application"
                centered
                visible={isVisibleModal}
                okText="Mettre à jour"
                cancelText="Annuler"
                onOk={onOk}
                onCancel={onCancel}
                destroyOnClose={true}
            >
                <Form name="Modification" form={updateForm} onFinish={onSubmit}>
                    <Form.Item
                        label="Nom de l'application"
                        name="name"
                        rules={[{ required: true, message: "Veuillez rentrer le nom de l'application !" }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ApplicationUpdateModal;
