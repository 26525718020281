import api from './_client';
import { SearchPaginationQuery } from './';

// Types
import { Application } from './apiTypes';

// Controller Interface
export interface ApplcationCreatePayload {
    body: {
        name: Application['name'];
        organization: Application['organization'];
    };
}

export interface ApplcationUpdatePayload {
    id: Application['id'];
    body: {
        name: Application['name'];
    };
}

export interface ApplicationIdPayload {
    id: Application['id'];
}

export type ApplicationListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: ApplicationListPayload = {}) => {
    return await api.get(`/applications`, { params: payload });
};

export const create = async (payload: ApplcationCreatePayload) => {
    return await api.post(`/applications`, payload.body);
};

export const update = async (payload: ApplcationUpdatePayload) => {
    return await api.put(`/applications/${payload.id}`, payload.body);
};

export const details = async (payload: ApplicationIdPayload) => {
    return await api.get(`/applications/${payload.id}`);
};

export const remove = async (payload: ApplicationIdPayload) => {
    return await api.delete(`/applications/${payload.id}`);
};
