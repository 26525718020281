export default Object.freeze({
    PAGE_SIZE: 50,
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Back office boilerplate',
        DEFAULT_TITLE: 'Back office boilerplate',
        DEFAULT_DESCRIPTION: 'Boilerplate for all back offices developments based on reactjs, ant design, redux...',
    }),
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS ?? '',
    ENABLE_DEBUG_LOGS: false, // process.env.NODE_ENV === 'production',
    DEBUG_LOGS_APP: '', // <app>_<service>
    VERSION: process.env.REACT_APP_VERSION ?? '',
});
