import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { Notification } from './apiTypes';

// Controller Interface

export interface NotificationsIdPayload {
    id: Notification['id'];
}

export type NotificationsListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: NotificationsListPayload = {}) => {
    return await api.get(`/notifications`, { params: payload });
};

export const details = async (payload: NotificationsIdPayload) => {
    return await api.get(`/notifications/${payload.id}`);
};
