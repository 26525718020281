export const requestReducer = <State, Result = any>(initialState: State) => ({
    trigger: () => ({
        loading: true,
        error: undefined,
        success: undefined,
    }),
    success: (state: State, payload: Result) => ({
        ...state,
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (_: State, payload: any) => ({
        loading: false,
        error: payload,
    }),
    reset: () => ({
        ...initialState,
    }),
});
