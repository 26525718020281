import { Action, ActionCreator } from 'redux';
import { DataAction } from './EzeeAction';
import { EzeeSimpleAction, EzeeSimpleActionReducer } from './EzeeSimpleAction';

export interface EzeeAsyncActionReducer<State, Payload = any, Result = any, ErrorResult = any>
    extends EzeeSimpleActionReducer<State, Payload> {
    success: (state: State, payload: Result) => State;
    failure: (state: State, payload: ErrorResult) => State;
    reset: (state: State) => State;
}

export class EzeeAsyncAction<State, Payload = any, Result = any, ErrorResult = any> extends EzeeSimpleAction<
    State,
    Payload
> {
    public success: ActionCreator<DataAction<Result>>;
    public failure: ActionCreator<DataAction<ErrorResult>>;
    public reset: ActionCreator<Action<string>>;

    protected actionReducer: EzeeAsyncActionReducer<State, Payload, Result, ErrorResult>;

    constructor(
        name: string,
        initialState: State,
        actionReducer: EzeeAsyncActionReducer<State, Payload, Result, ErrorResult>
    ) {
        super(name, initialState, actionReducer);
        this.actionReducer = actionReducer;

        this.success = (payload: Result) => ({
            type: this.type.actionTypes.success,
            payload,
        });

        this.failure = (payload: ErrorResult) => ({
            type: this.type.actionTypes.failure,
            payload,
        });

        this.reset = () => ({
            type: this.type.actionTypes.reset,
        });
    }
}
