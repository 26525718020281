import React, { FC, useEffect, useState } from 'react';

// Antd lib
import { Form, Input, message, Modal } from 'antd';

import { FormProps } from 'antd/lib/form';

// Store
import { useSelector } from 'react-redux';
import { create as organizationCreateApiCall, getOrganizationsCreateState } from '../../../store/actions/organizations';

// Api Types

// Helpers
import { useActions, usePrevious } from '../../../hooks';

// Style
import '../../../assets/styles/ApplicationsModal.less';

interface OrganizationCreateModalProps {
    hideComponent: () => void;
    isComponentVisible: boolean;
}

const OrganizationCreateModal: FC<OrganizationCreateModalProps> = ({ hideComponent, isComponentVisible }) => {
    const createOrganizations = useActions(organizationCreateApiCall.trigger);
    const createOrganizationsState = useSelector(getOrganizationsCreateState);
    const [organizationCreationForm] = Form.useForm();

    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(isComponentVisible);

    const previous = usePrevious({
        createOrganizationsState,
    });

    const onOk = () => {
        organizationCreationForm.submit();
        setIsVisibleModal(false);
        hideComponent();
    };

    const onCancel = () => {
        setIsVisibleModal(false);
        hideComponent();
    };

    const onSubmit: FormProps['onFinish'] = (values) => {
        createOrganizations({
            body: {
                name: values.name,
            },
        });
        setIsVisibleModal(false);
        hideComponent();
    };

    // Use Effect action
    // Application Client Creation State
    useEffect(() => {
        if (previous?.createOrganizationsState.loading && !createOrganizationsState.loading) {
            if (createOrganizationsState.success) {
                message.success("L'organisation client a été créée");
            }
        }
    }, [previous, createOrganizationsState]);

    // Laod application modal in startup
    useEffect(() => {
        setIsVisibleModal(isComponentVisible);
    }, [isComponentVisible, setIsVisibleModal]);

    return (
        <>
            <Modal
                title="Création d'une organisation"
                centered
                visible={isVisibleModal}
                okText="Créer"
                cancelText="Annuler"
                onOk={onOk}
                onCancel={onCancel}
                destroyOnClose={true}
            >
                <Form name="Création" form={organizationCreationForm} onFinish={onSubmit}>
                    <Form.Item
                        label="Nom de l'organisation"
                        name="name"
                        rules={[{ required: true, message: "Veuillez rentrer le nom de l'organisation !" }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default OrganizationCreateModal;
