import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Organization, ListResponse } from '../api/apiTypes';

// Controlers
import {
    OrganizationIdPayload,
    OrganizationListPayload,
    OrganizationCreatePayload,
    OrganizationUpdatePayload,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
} from '../api/organizations';

// States
export interface OrganizationsState {
    create: RequestState<Organization>;
    update: RequestState<Organization>;
    remove: RequestState<Organization>;
    details: RequestState<Organization>;
    list: RequestState<ListResponse<Organization>>;
}

const initialState: OrganizationsState = {
    create: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
    update: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
};

export const create = new EzeeAsyncAction<OrganizationsState['create'], OrganizationCreatePayload, Organization>(
    'organizations/create',
    initialState.create,
    requestReducer<OrganizationsState['create'], Organization>(initialState.create)
);

export const update = new EzeeAsyncAction<OrganizationsState['update'], OrganizationUpdatePayload, Organization>(
    'organizations/update',
    initialState.update,
    requestReducer<OrganizationsState['update'], Organization>(initialState.update)
);

export const remove = new EzeeAsyncAction<OrganizationsState['remove'], OrganizationIdPayload, Organization>(
    'organizations/remove',
    initialState.remove,
    requestReducer<OrganizationsState['remove'], Organization>(initialState.remove)
);

export const details = new EzeeAsyncAction<OrganizationsState['details'], OrganizationIdPayload, Organization>(
    'organizations/details',
    initialState.details,
    requestReducer<OrganizationsState['details'], Organization>(initialState.details)
);

export const list = new EzeeAsyncAction<
    OrganizationsState['list'],
    OrganizationListPayload,
    ListResponse<Organization>
>(
    'organizations/list',
    initialState.list,
    requestReducer<OrganizationsState['list'], ListResponse<Organization>>(initialState.list)
);

// Reducer
export const organizationsReducer = combineReducers<OrganizationsState>({
    list: list.reducer,
    update: update.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
});

// Saga
export function* organizationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getOrganizationsState = (state: MainReducerState) => state.organizations;
export const getOrganizationsListState = (state: MainReducerState) => state.organizations.list;
export const getOrganizationsCreateState = (state: MainReducerState) => state.organizations.create;
export const getOrganizationsUpdateState = (state: MainReducerState) => state.organizations.update;
export const getOrganizationsRemoveState = (state: MainReducerState) => state.organizations.remove;
export const getOrganizationsDetailsState = (state: MainReducerState) => state.organizations.details;
