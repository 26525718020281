import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Recipient, ListResponse } from '../api/apiTypes';

// Controlers
import {
    RecipientsIdPayload,
    RecipientsListPayload,
    list as listApiCall,
    details as detailsApiCall,
} from '../api/recipients';

// States
export interface RecipientsState {
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Recipient;
        };
    };
    list: RequestState<ListResponse<Recipient>>;
}

const initialState: RecipientsState = {
    details: {
        error: '',
        data: {},
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
};

export const details = new EzeeAsyncAction<RecipientsState['details'], RecipientsIdPayload, Recipient>(
    'recipients/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const list = new EzeeAsyncAction<RecipientsState['list'], RecipientsListPayload, ListResponse<Recipient>>(
    'recipients/list',
    initialState.list,
    requestReducer<RecipientsState['list'], ListResponse<Recipient>>(initialState.list)
);

// Reducer
export const recipientsReducer = combineReducers<RecipientsState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* recipientsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getRecipientsState = (state: MainReducerState) => state.recipients;
export const getRecipientsListState = (state: MainReducerState) => state.recipients.list;
export const getRecipientsDetailsState = (state: MainReducerState) => state.recipients.details;
export const getRecipientStateById = (id: Recipient['id']) => (state: MainReducerState) => ({
    loading: state.recipients.details.loading,
    error: state.recipients.details.error,
    data: state.recipients.details.data[id],
});
