import { RcFile } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

import api from './_client';
import { User } from './apiTypes';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = async (payload: LoginPayload) => await api.post('/auth', payload);

export const logout = async () => await api.post('/auth/logout');

export interface ForgottenPasswordPayload {
    username: User['email'];
}

export const forgottenPassword = async (payload: ForgottenPasswordPayload) => await api.post('/auth/reset', payload);

export interface ResetPasswordPayload {
    token: string;
    password: string;
}

export const resetPassword = async (payload: ResetPasswordPayload) => await api.post('/signedAction', payload);

export interface ResendInvitationPayload {
    token: string;
}

export const resendInvitation = async (payload: ResendInvitationPayload) => await api.post('/auth/reset', payload);

export interface UpdateMePayload {
    avatarFile: RcFile;
}

export const update = async (payload: UpdateMePayload) => {
    const formData = serialize(payload, { indices: true });

    return await api.put('/me', formData);
};

export const checkLoginStatus = async () => await api.get('/me');
