import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Dropdown, Avatar, Menu, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import '../assets/styles/Header.less';

import { logout as logoutAction, getUser } from '../store/actions/auth';

import { LayoutContext } from '../context/LayoutContext';
import { getRoute, RoutePathName } from '../routes';
import { useActions } from '../hooks';

const Header: FC = () => {
    const logout = useActions(logoutAction.trigger);
    const user = useSelector(getUser);
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useContext(LayoutContext);
    const menu = (
        <Menu>
            <Menu.Item>
                <Link to={getRoute(RoutePathName.login)} onClick={logout}>
                    Se déconnecter
                </Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout.Header id="main-header">
            <Button
                type="link"
                id="sidebar-collapse-button"
                onClick={setIsSidebarCollapsed.bind(null, !isSidebarCollapsed)}
            >
                {isSidebarCollapsed ? (
                    <MenuUnfoldOutlined className="trigger" />
                ) : (
                    <MenuFoldOutlined className="trigger" />
                )}
            </Button>
            {user?.firstName && (
                <Dropdown overlayClassName="user-card-dropdown" trigger={['click']} overlay={menu}>
                    <Link to="" className="user-card">
                        <Avatar size={32}>{user?.firstName.substr(0, 1)}</Avatar>
                        {user?.firstName}
                    </Link>
                </Dropdown>
            )}
        </Layout.Header>
    );
};

export default Header;
