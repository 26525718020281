import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Notification, ListResponse } from '../api/apiTypes';

// Controlers
import {
    NotificationsIdPayload,
    NotificationsListPayload,
    list as listApiCall,
    details as detailsApiCall,
} from '../api/notifications';

// States
export interface NotificationState {
    details: {
        loading: boolean;
        error?: any;
        data: {
            [id: string]: Notification;
        };
    };
    list: RequestState<ListResponse<Notification>>;
}

const initialState: NotificationState = {
    details: {
        error: '',
        data: {},
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
};

export const details = new EzeeAsyncAction<NotificationState['details'], NotificationsIdPayload, Notification>(
    'notifications/details',
    initialState.details,
    {
        trigger: (state) => ({
            ...state,
            error: undefined,
            loading: true,
        }),
        success: (state, payload) => ({
            data: {
                ...state.data,
                [payload.id]: payload,
            },
            loading: false,
        }),
        failure: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
        reset: () => ({
            ...initialState.details,
        }),
    }
);

export const list = new EzeeAsyncAction<
    NotificationState['list'],
    NotificationsListPayload,
    ListResponse<Notification>
>(
    'notifications/list',
    initialState.list,
    requestReducer<NotificationState['list'], ListResponse<Notification>>(initialState.list)
);

// Reducer
export const notificationsReducer = combineReducers<NotificationState>({
    list: list.reducer,
    details: details.reducer,
});

// Saga
export function* notificationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getNotificationsState = (state: MainReducerState) => state.notifications;
export const getNotificationsListState = (state: MainReducerState) => state.notifications.list;
export const getNotificationsDetailsState = (state: MainReducerState) => state.notifications.details;
/* export const getNotificationsStateById = (id: Notification['id']) => (state: MainReducerState) => ({
    loading: state.notifications.details.loading,
    error: state.notifications.details.error,
    data: state.notifications.details.data[id],
}); */
