import { useParams } from 'react-router-dom';
import React, { FC /*, useEffect, useState */ } from 'react';

// Antd lib
// import Table, { ColumnProps } from 'antd/lib/table';
import { /* Button, message, Popconfirm, */ Col, Row } from 'antd';

// import { AppstoreAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

// Store
// import { useSelector } from 'react-redux';

// Helpers
// import { useActions, usePrevious } from '../../hooks';

// Components
import Seo from '../../components/Seo';
import ListTitle from '../../components/ListTitle';
import LayoutHeader from '../../components/LayoutHeader';

// Style
import '../../assets/styles/MessageTriggers.less';

const MessageTriggers: FC = () => {
    const { id } = useParams<{ id: string }>();

    console.log('id :', id);

    return (
        <>
            <Seo title="Message Triggers" />
            <LayoutHeader>
                <Row>
                    <Col span={12}>
                        <ListTitle count={0}>Message Triggers</ListTitle>
                    </Col>
                </Row>
            </LayoutHeader>
        </>
    );
};

export default MessageTriggers;
