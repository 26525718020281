import { Layout } from 'antd';
import React, { FC } from 'react';

import { LayoutContextProvider } from '../context/LayoutContext';
import Header from './Header';
import MainMenu from './MainMenu';

const MainLayout: FC = ({ children }) => (
    <LayoutContextProvider>
        <Layout id="main-layout">
            <MainMenu />
            <Layout>
                <Header />
                <Layout.Content id="main-content">{children}</Layout.Content>
            </Layout>
        </Layout>
    </LayoutContextProvider>
);

export default MainLayout;
