import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { ApplicationClient, DeviceType } from '../api/apiTypes';

// Controlers
import {
    ApplicationClientIdPayload,
    ApplicationClientCreatePayload,
    ApplcationClientUpdatePayload,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
} from '../api/applicationsClients';

// States
export interface ApplicationsClientsState {
    create: RequestState<ApplicationClient>;
    update: RequestState<ApplicationClient>;
    remove: RequestState<ApplicationClient>;
    details: RequestState<ApplicationClient>;
}

const initialState: ApplicationsClientsState = {
    create: {
        data: {
            id: '',
            name: '',
            apiKey: '',
            isPrivate: false,
            reference: '',
            type: DeviceType.nothing,
            options: {},
        },
        loading: false,
    },
    update: {
        data: {
            id: '',
            name: '',
            apiKey: '',
            isPrivate: false,
            reference: '',
            type: DeviceType.nothing,
            options: {},
        },
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        data: {
            id: '',
            name: '',
            apiKey: '',
            isPrivate: false,
            reference: '',
            type: DeviceType.nothing,
            options: {},
        },
        loading: false,
    },
};

export const create = new EzeeAsyncAction<
    ApplicationsClientsState['create'],
    ApplicationClientCreatePayload,
    ApplicationClient
>(
    'applicationsClients/create',
    initialState.create,
    requestReducer<ApplicationsClientsState['create'], ApplicationClient>(initialState.create)
);

export const update = new EzeeAsyncAction<
    ApplicationsClientsState['update'],
    ApplcationClientUpdatePayload,
    ApplicationClient
>(
    'applicationsClients/update',
    initialState.update,
    requestReducer<ApplicationsClientsState['update'], ApplicationClient>(initialState.update)
);

export const remove = new EzeeAsyncAction<
    ApplicationsClientsState['remove'],
    ApplicationClientIdPayload,
    ApplicationClient
>(
    'applicationsClients/remove',
    initialState.remove,
    requestReducer<ApplicationsClientsState['remove'], ApplicationClient>(initialState.remove)
);

export const details = new EzeeAsyncAction<
    ApplicationsClientsState['details'],
    ApplicationClientIdPayload,
    ApplicationClient
>(
    'applicationsClients/details',
    initialState.details,
    requestReducer<ApplicationsClientsState['details'], ApplicationClient>(initialState.details)
);

// Reducer
export const applicationsClientsReducer = combineReducers<ApplicationsClientsState>({
    update: update.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
});

// Saga
export function* applicationsClientsSaga() {
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getApplicationsState = (state: MainReducerState) => state.applicationsClients;
export const getApplicationsClientsCreateState = (state: MainReducerState) => state.applicationsClients.create;
export const getApplicationsClientsUpdateState = (state: MainReducerState) => state.applicationsClients.update;
export const getApplicationsClientsRemoveState = (state: MainReducerState) => state.applicationsClients.remove;
export const getApplicationsClientsDetailsState = (state: MainReducerState) => state.applicationsClients.details;
