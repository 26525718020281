import React, { FC, useState, ReactChild } from 'react';
import { Button, Descriptions } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import '../assets/styles/TruncatedDescriptions.less';
import { useIsMobile } from '../hooks';

export interface TruncatedDescriptionsProps<T> {
    columns?: number;
    items?: T[];
    maxItems: number;
    maxItemsMobile?: number;
    renderItem?: (item: T, index: number) => ReactChild;
}

type TruncatedDescriptionsI<T = any> = FC<TruncatedDescriptionsProps<T>>;

const TruncatedDescriptions: TruncatedDescriptionsI = ({
    columns,
    items,
    maxItems,
    maxItemsMobile = 6,
    renderItem,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isMobile = useIsMobile();
    const max = isMobile ? maxItemsMobile : maxItems;
    const itemCountToExpand = items ? items.length - max : 0;
    const truncatedItems = items?.slice(0, isExpanded ? items.length : max);
    const onToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return items ? (
        <div className="truncated-descriptions">
            <Descriptions column={columns ?? (isMobile ? 1 : 2)}>
                {truncatedItems?.map((item, index) => (
                    <Descriptions.Item key={index}>
                        {typeof renderItem === 'function' ? renderItem(item, index) : item}
                    </Descriptions.Item>
                ))}
            </Descriptions>
            {itemCountToExpand > 0 && (
                <Button type="link" onClick={onToggleExpand}>
                    {isExpanded ? (
                        <>
                            Voir moins
                            <UpOutlined />
                        </>
                    ) : (
                        <>
                            {`Voir plus (${itemCountToExpand})`}
                            <DownOutlined />
                        </>
                    )}
                </Button>
            )}
        </div>
    ) : null;
};

export default TruncatedDescriptions;
