import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { Event, Recipient } from './apiTypes';

// Controller Interface

export interface EventIdPayload {
    id: Event['id'];
}

export interface EventListPayload extends SearchPaginationQuery {
    recipientRef: Recipient['id'];
}

// Routes
export const list = async (payload: EventListPayload) => {
    return await api.get(`/recipients/${payload.recipientRef}/events`);
};

export const details = async (payload: EventIdPayload) => {
    return await api.get(`/recpients/.../evenets/${payload.id}`);
};
