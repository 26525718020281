import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Alert } from 'antd';

import { login as loginAction, getAuthState } from '../../store/actions/auth';

import logo from '../../assets/images/logo.svg';
import Seo from '../../components/Seo';
import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useActions } from '../../hooks';

const Login: FC = () => {
    const login = useActions(loginAction.trigger);
    const authState = useSelector(getAuthState);
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        login(values);
    };

    if (authState.isConnected) {
        return <Redirect to="/" />;
    }

    let error = authState.error
        ? 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur'
        : null;

    if (authState.error?.status) {
        switch (authState.error.status) {
            case 401:
                error = 'Non autorisé';
                break;

            case 403:
                error = 'Mot de passe incorrect';
                break;

            case 404:
                error = "Nom d'utilisateur introuvable";
                break;

            default:
                error = 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur';
                break;
        }
    }

    return (
        <LoginLayout>
            <Seo title="Connexion" />
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                <img className="logo" src={logo} alt="logo" />

                <Form.Item name="username" rules={[{ required: true, message: 'Veuillez renseigner votre e-mail' }]}>
                    <Input size="large" placeholder="Nom d'utilisateur" />
                </Form.Item>

                <Form.Item
                    className="password-row"
                    name="password"
                    rules={[{ required: true, message: 'Veuillez renseigner votre mot de passe' }]}
                >
                    <Input.Password size="large" placeholder="Mot de passe" />
                </Form.Item>

                <Form.Item>
                    {error ? (
                        <div className="login-error-message">
                            <Alert type="error" message={error} showIcon />
                        </div>
                    ) : null}

                    <Button type="primary" size="large" block htmlType="submit" loading={authState.loading}>
                        Connexion
                    </Button>
                </Form.Item>

                <Link to={getRoute(RoutePathName.forgottenPassword)}>Mot de passe oublié ?</Link>
            </Form>
        </LoginLayout>
    );
};

export default Login;
