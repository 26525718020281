import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Device, ListResponse } from '../api/apiTypes';

// Controlers
import { DeviceListPayload, list as listApiCall } from '../api/devices';

// States
export interface DeviceState {
    list: RequestState<ListResponse<Device>>;
}

const initialState: DeviceState = {
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
};

export const list = new EzeeAsyncAction<DeviceState['list'], DeviceListPayload, ListResponse<Device>>(
    'devices/list',
    initialState.list,
    requestReducer<DeviceState['list'], ListResponse<Device>>(initialState.list)
);

// Reducer
export const deviceReducer = combineReducers<DeviceState>({
    list: list.reducer,
});

// Saga
export function* devicesSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
}

// Store helpers
export const getDeviceState = (state: MainReducerState) => state.device;
export const getDeviceListState = (state: MainReducerState) => state.device.list;
