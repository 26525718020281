import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { Recipient } from './apiTypes';

// Controller Interface

export interface RecipientsIdPayload {
    id: Recipient['id'];
}

export type RecipientsListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: RecipientsListPayload = {}) => {
    return await api.get(`/recipients`, { params: payload });
};

export const details = async (payload: RecipientsIdPayload) => {
    return await api.get(`/recipients/${payload.id}`);
};
