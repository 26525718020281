import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Application, ListResponse } from '../api/apiTypes';

// Controlers
import {
    ApplicationIdPayload,
    ApplicationListPayload,
    ApplcationCreatePayload,
    ApplcationUpdatePayload,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
} from '../api/applications';

// States
export interface ApplicationsState {
    create: RequestState<Application>;
    update: RequestState<Application>;
    remove: RequestState<Application>;
    details: RequestState<Application>;
    list: RequestState<ListResponse<Application>>;
}

const initialState: ApplicationsState = {
    create: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
    update: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        data: {
            id: '',
            name: '',
        },
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
};

export const create = new EzeeAsyncAction<ApplicationsState['create'], ApplcationCreatePayload, Application>(
    'applications/create',
    initialState.create,
    requestReducer<ApplicationsState['create'], Application>(initialState.create)
);

export const update = new EzeeAsyncAction<ApplicationsState['update'], ApplcationUpdatePayload, Application>(
    'applications/update',
    initialState.update,
    requestReducer<ApplicationsState['update'], Application>(initialState.update)
);

export const remove = new EzeeAsyncAction<ApplicationsState['remove'], ApplicationIdPayload, Application>(
    'applications/remove',
    initialState.remove,
    requestReducer<ApplicationsState['remove'], Application>(initialState.remove)
);

export const details = new EzeeAsyncAction<ApplicationsState['details'], ApplicationIdPayload, Application>(
    'applications/details',
    initialState.details,
    requestReducer<ApplicationsState['details'], Application>(initialState.details)
);

export const list = new EzeeAsyncAction<ApplicationsState['list'], ApplicationListPayload, ListResponse<Application>>(
    'applications/list',
    initialState.list,
    requestReducer<ApplicationsState['list'], ListResponse<Application>>(initialState.list)
);

// Reducer
export const applicationsReducer = combineReducers<ApplicationsState>({
    list: list.reducer,
    update: update.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
});

// Saga
export function* applicationsSaga() {
    yield takeLatest(list.type.trigger, simpleAsyncSaga(listApiCall, list));
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

// Store helpers
export const getApplicationsState = (state: MainReducerState) => state.applications;
export const getApplicationsListState = (state: MainReducerState) => state.applications.list;
export const getApplicationsCreateState = (state: MainReducerState) => state.applications.create;
export const getApplicationsUpdateState = (state: MainReducerState) => state.applications.update;
export const getApplicationsRemoveState = (state: MainReducerState) => state.applications.remove;
export const getApplicationsDetailsState = (state: MainReducerState) => state.applications.details;
