import { SearchPaginationQuery } from './';
import api from './_client';
import { User } from './apiTypes';

export interface UserDetailsPayload {
    id: User['id'];
}

export type UserListPayload = SearchPaginationQuery;

export const details = async (payload: UserDetailsPayload) => await api.get(`/users/${payload.id}`);

export const list = async (payload: UserListPayload = {}) => {
    return await api.get(`/users`, { params: payload });
};
