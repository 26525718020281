import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Descriptions, PageHeader, Table, Tabs, Tooltip } from 'antd';
import { useParams, RouteComponentProps, useHistory } from 'react-router-dom';

import { Device, Event, Notification, Recipient } from '../../store/api/apiTypes';
import { details as recipientDetails, getRecipientStateById } from '../../store/actions/recipients';

import { getRoute, RoutePathName } from '../../routes';
import LayoutHeader from '../../components/LayoutHeader';
import { useActions } from '../../hooks';
import { getNotificationsListState, list as notificationList } from '../../store/actions/notification';
import { getEventListState, list as eventList } from '../../store/actions/event';
import { getDeviceListState, list as deviceList } from '../../store/actions/devices';
import { ColumnProps, TableProps } from 'antd/lib/table';
import useQueryParams from '../../hooks/queryParams';

interface MatchParams {
    id: Recipient['id'];
}

const RecipientsDetails: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
    const pageSize = 20;
    const [loadRecipientDetails, loadNotifications, loadEvents, loadDevice] = useActions([
        recipientDetails.trigger,
        notificationList.trigger,
        eventList.trigger,
        deviceList.trigger,
    ]);
    const recipientDetailsState = useSelector(getRecipientStateById(match.params.id));
    const notificationListState = useSelector(getNotificationsListState);
    const eventListState = useSelector(getEventListState);
    const deviceListState = useSelector(getDeviceListState);
    const history = useHistory();
    const { id } = useParams<{ id?: string }>();
    const [queryParams, setQueryParams] = useQueryParams('recipients');
    const onClickBackButton = () => {
        history.push(getRoute(RoutePathName.recipientsList), { isBack: true });
    };
    const pageNotifications =
        queryParams.get('notification') !== null ? parseInt(queryParams.get('notification')!, 10) || 1 : 1;
    const pageEvents = queryParams.get('events') !== null ? parseInt(queryParams.get('events')!, 10) || 1 : 1;
    const pageDevice = queryParams.get('devices') !== null ? parseInt(queryParams.get('devices')!, 10) || 1 : 1;

    const onTableChangeNotification: TableProps<Notification>['onChange'] = (pagination) => {
        setQueryParams({
            page: pagination.current ?? 1 - 1,
        });
    };
    const onTableChangeEvent: TableProps<Event>['onChange'] = (pagination) => {
        setQueryParams({
            page: pagination.current ?? 1 - 1,
        });
    };

    const onTableChangeDevice: TableProps<Device>['onChange'] = (pagination) => {
        setQueryParams({
            page: pagination.current ?? 1 - 1,
        });
    };

    useEffect(() => {
        if (recipientDetailsState.data?.id) {
            loadNotifications({ recipient: recipientDetailsState.data.id });
        }
    }, [loadNotifications, recipientDetailsState.data?.id]);

    useEffect(() => {
        if (recipientDetailsState.data?.id) {
            loadEvents({ recipientRef: recipientDetailsState.data.id });
        }
    }, [loadEvents, recipientDetailsState.data?.id]);

    useEffect(() => {
        if (recipientDetailsState.data?.id) {
            loadDevice({ recipientId: recipientDetailsState.data.id });
        }
    }, [loadDevice, recipientDetailsState.data?.id]);

    useEffect(() => {
        loadRecipientDetails({
            id: id,
            page: pageNotifications - 1,
            pageSize: pageSize,
        });
    }, [loadRecipientDetails, id, pageSize, pageNotifications]);

    useEffect(() => {
        loadRecipientDetails({
            id: id,
            page: pageEvents - 1,
            pageSize: pageSize,
        });
    }, [loadRecipientDetails, id, pageSize, pageEvents]);

    useEffect(() => {
        loadRecipientDetails({
            id: id,
            page: pageDevice - 1,
            pageSize: pageSize,
        });
    }, [loadRecipientDetails, id, pageSize, pageDevice]);

    const { TabPane } = Tabs;

    const notifColumns: Array<ColumnProps<Notification>> = [
        {
            title: 'Titre',
            render: (record: Notification) => <Tooltip title={record.id}>{record.payload.title.fr}</Tooltip>,
        },
        {
            title: 'Texte',
            render: (record: Notification) => record.payload.text.fr,
        },
        {
            title: 'Status',
            render: (record: Notification) => record.status,
        },
        {
            title: 'Channel',
            render: (record) => record.channel,
        },
    ];

    const eventColumns: Array<ColumnProps<Event>> = [
        {
            title: 'Slug',
            render: (record: Event) => <Tooltip title={record.id}>{record.slug}</Tooltip>,
        },
        {
            title: 'Scheduled',
            render: (record: Event) => record.scheduled,
        },
    ];

    const deviceColumns: Array<ColumnProps<Device>> = [
        {
            title: 'Appareil',
            render: (record: Device) => <Tooltip title={record.id}>{record.deviceType}</Tooltip>,
        },
        {
            title: 'Device ID',
            render: (record: Device) => record.deviceId,
        },
        {
            title: 'Application Client',
            render: (record: Device) => record.applicationClient?.name,
        },
    ];

    const rowKeyNotification = (item: Notification) => `${item.id}`;

    const rowKeyEvent = (item: Event) => `${item.id}`;

    const rowKeyDevice = (item: Device) => `${item.id}`;

    const notificationTable = (
        <>
            <Table<Notification>
                className="notifications-list"
                columns={notifColumns}
                loading={notificationListState.loading}
                rowKey={rowKeyNotification}
                dataSource={notificationListState.data?.items}
                pagination={{
                    total: notificationListState.data?.totalCount,
                    current: pageNotifications,
                    pageSize: pageSize,
                }}
                onChange={onTableChangeNotification}
            />
        </>
    );

    const eventTable = (
        <>
            <Table<Event>
                className="notifications-list"
                columns={eventColumns}
                loading={notificationListState.loading}
                rowKey={rowKeyEvent}
                dataSource={eventListState.data?.items}
                pagination={{
                    total: notificationListState.data?.totalCount,
                    current: pageEvents,
                    pageSize: pageSize,
                }}
                onChange={onTableChangeEvent}
            />
        </>
    );

    const devicesTable = (
        <>
            <Table<Device>
                className="notifications-list"
                columns={deviceColumns}
                loading={notificationListState.loading}
                rowKey={rowKeyDevice}
                dataSource={deviceListState.data?.items}
                pagination={{
                    total: notificationListState.data?.totalCount,
                    current: pageEvents,
                    pageSize: pageSize,
                }}
                onChange={onTableChangeDevice}
            />
        </>
    );

    return (
        <>
            <LayoutHeader>
                <PageHeader onBack={onClickBackButton} title="Retour">
                    <Descriptions size="small" column={3}>
                        <Descriptions.Item label="Application">
                            {recipientDetailsState.data?.application?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Organization">
                            {recipientDetailsState.data?.organization?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Created by">
                            {recipientDetailsState.data?.context?.firstName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Program ID">
                            {recipientDetailsState.data?.context?.program}
                        </Descriptions.Item>
                        <Descriptions.Item label="Recipient ID">{recipientDetailsState.data?.id}</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
            </LayoutHeader>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Notification" key="1">
                    {notificationTable}
                </TabPane>
                <TabPane tab="Event" key="2">
                    {eventTable}
                </TabPane>
                <TabPane tab="Devices" key="3">
                    {devicesTable}
                </TabPane>
            </Tabs>
        </>
    );
};

export default RecipientsDetails;
