import React, { FC } from 'react';
import { Typography, Card, Space, Row, Col } from 'antd';

import Seo from '../../components/Seo';
import TruncatedDescriptions from '../../components/TruncatedDescriptions';
import TruncatedText from '../../components/TruncatedText';
import CardSkeleton from '../../components/CardSkeleton';
import Img from '../../components/Img';
import LayoutHeader from '../../components/LayoutHeader';

const Home: FC = () => {
    return (
        <>
            <Seo title="Home page" />
            <LayoutHeader>
                <Typography.Title level={1}>Home page</Typography.Title>
            </LayoutHeader>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Card title={<code>{'<TruncatedDescriptions />'}</code>}>
                    <TruncatedDescriptions
                        columns={2}
                        // tslint:disable-next-line: max-line-length
                        items={[
                            'handsome',
                            'each',
                            'large',
                            'major',
                            'plane',
                            'bet',
                            'beauty',
                            'attention',
                            'during',
                            'given',
                            'hit',
                            'fact',
                            'captain',
                            'realize',
                            'clear',
                            'nearly',
                            'drove',
                            'climate',
                            'morning',
                            'found',
                            'play',
                            'seed',
                            'week',
                            'stomach',
                        ]}
                        maxItems={9}
                    />
                </Card>
                <Card title={<code>{'<TruncatedText />'}</code>}>
                    <TruncatedText maxHeight={100} withFade>
                        <p>
                            {
                                'rush current mood plan sign weak skin shadow every foreign mixture whatever machinery sand leather street adult buried claws palace importance opposite past environment consider began choose stream minute count ice usually lift planned exchange bicycle magic replied few title poetry eventually present quick hello say motion pot met character quick helpful talk impossible compound whispered vegetable train cross cloud river chart correct show jack tightly read done red only inside choose sick every square bring certainly something cut typical say drive ground missing whose as property rest moment dear excellent dawn nice truth classroom parallel found include difficult sad mental bone surprise pond home shadow scientist weak cover folks spoken body shown end similar kids share play which tool judge fought ten count threw either forward class porch idea thirty rest sang won cool animal moving positive fellow member plain statement belong him pretty land over hill be forward most appearance to information manufacturing conversation grain suddenly range mark sand say however quiet than stone running us along idea like face pig color military fish car account nearly settlers mother symbol problem does smaller path due place here pile book verb lie myself donkey cup modern late men thumb anybody brain breathing decide grandfather dirty trade won breath camp way flag opinion press each prove '
                            }
                        </p>
                    </TruncatedText>
                </Card>
                <Card title={<code>{'<CardSkeleton />'}</code>}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <p>default</p>
                            <CardSkeleton />
                        </Col>
                        <Col span={8}>
                            <p>4 rows</p>
                            <CardSkeleton rows={4} />
                        </Col>
                        <Col span={8}>
                            <p>image</p>
                            <CardSkeleton image />
                        </Col>
                    </Row>
                </Card>
                <Card title={<code>{'<Img />'}</code>}>
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        <Img src="https://placekitten.com/600/300" alt="cats" height={300} width={600} />
                        <Img src="https://broken-url" alt="cats" height={300} width={600} />
                    </Space>
                </Card>
            </Space>
        </>
    );
};

export default Home;
