import { Badge, Typography } from 'antd';
import React, { FC } from 'react';
import { TitleProps } from 'antd/lib/typography/Title';

import '../assets/styles/ListTitle.less';

interface ListTitleProps extends TitleProps {
    count?: number;
}

const ListTitle: FC<ListTitleProps> = ({ children, count, level = 1 }) => (
    <Typography.Title level={level} className="list-title">
        {children}
        <Badge count={count ?? 0} />
    </Typography.Title>
);

export default ListTitle;
