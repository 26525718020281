import React, { FC, useEffect, useState } from 'react';

// Antd lib
import { Form, Input, message, Modal, Select } from 'antd';

import { FormProps } from 'antd/lib/form';

// Store
import { useSelector } from 'react-redux';
import { create as applicationCreateApiCall, getApplicationsCreateState } from '../../../store/actions/applications';

import { list as organizationListApiCall, getOrganizationsListState } from '../../../store/actions/organizations';

// Api Types
import { Organization } from '../../../store/api/apiTypes';

// Helpers
import { useActions, usePrevious } from '../../../hooks';

// Style
import '../../../assets/styles/ApplicationsModal.less';

interface ApplicationCreateModalProps {
    hideComponent: () => void;
    isComponentVisible: boolean;
}

const ApplicationCreateModal: FC<ApplicationCreateModalProps> = ({ hideComponent, isComponentVisible }) => {
    const [loadOrganizations, createApplications] = useActions([
        organizationListApiCall.trigger,
        applicationCreateApiCall.trigger,
    ]);
    const listOrganizationsState = useSelector(getOrganizationsListState);
    const createApplicationsState = useSelector(getApplicationsCreateState);
    const [creationForm] = Form.useForm();

    const [organizationList, setOrganizationList] = useState<Organization[]>([]);
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(isComponentVisible);

    const optionsOrganization = organizationList.map((organization) => (
        <Select.Option key={organization.id} value={organization.id}>
            {organization.name}
        </Select.Option>
    ));

    const previous = usePrevious({
        listOrganizationsState,
        createApplicationsState,
    });

    const onOk = () => {
        creationForm.submit();
    };

    const onCancel = () => {
        setIsVisibleModal(false);
        hideComponent();
    };

    const onSubmit: FormProps['onFinish'] = (values) => {
        createApplications({
            body: {
                name: values.name,
                organization: values.organization,
            },
        });
        setIsVisibleModal(false);
        hideComponent();
    };

    // Use Effect action
    // Application Creation State
    useEffect(() => {
        if (previous?.createApplicationsState.loading && !createApplicationsState.loading) {
            if (createApplicationsState.success) {
                message.success("L'application a été créée");
            }
        }
    }, [previous, createApplicationsState]);

    // Get organization list
    useEffect(() => {
        if (previous?.listOrganizationsState.loading && !listOrganizationsState.loading) {
            if (listOrganizationsState.success) {
                setOrganizationList(listOrganizationsState.data?.items ?? []);
            }
        }
    }, [previous, listOrganizationsState]);

    // Laod application modal in startup
    useEffect(() => {
        loadOrganizations();
        setIsVisibleModal(isComponentVisible);
    }, [isComponentVisible, setIsVisibleModal, loadOrganizations]);

    return (
        <>
            <Modal
                title="Création d'une application"
                centered
                visible={isVisibleModal}
                okText="Créer"
                cancelText="Annuler"
                onOk={onOk}
                onCancel={onCancel}
            >
                <Form name="Création" form={creationForm} onFinish={onSubmit}>
                    <Form.Item
                        label="Nom de l'application"
                        name="name"
                        rules={[{ required: true, message: "Veuillez rentrer le nom de l'application !" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Organisation"
                        name="organization"
                        rules={[{ required: true, message: 'Veuillez séléctionner une organisation !' }]}
                    >
                        <Select showSearch placeholder="Sélectionner une organization" defaultActiveFirstOption={false}>
                            {optionsOrganization}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ApplicationCreateModal;
