import React, { FC, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Typography, Switch, Button, Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { getCookiesState, cookies, CookieNames } from '../../store/actions/cookies';

import {
    acceptAllCookies,
    refuseAllCookies,
    setCookiesOptions,
    removeGoogleTagManager,
    addGoogleTagManager,
} from '../../helpers/cookies';
import Seo from '../../components/Seo';
import { useActions } from '../../hooks';

const Privacy: FC = () => {
    const [accept, refuse, toggle] = useActions([
        cookies.actions.acceptAll,
        cookies.actions.refuse,
        cookies.actions.toggle,
    ]);
    const cookiesState = useSelector(getCookiesState);
    const [_, setCookie, removeCookie] = useCookies(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [showSuccess, setShowSuccess] = useState(false);
    const onToggle = (cookie: string) => {
        toggle(cookie);

        if (cookie === CookieNames.GTAG) {
            if (cookiesState.gtag) {
                removeGoogleTagManager(removeCookie);
                setCookie(CookieNames.GTAG, 0, setCookiesOptions);
            } else {
                addGoogleTagManager();
                setCookie(CookieNames.GTAG, 1, setCookiesOptions);
            }
        }
    };
    const onClickAccept = useCallback(() => {
        acceptAllCookies(setCookie);
        accept();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [accept, setCookie]);
    const onClickRefuse = useCallback(() => {
        refuseAllCookies(setCookie, removeCookie);
        refuse();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [refuse, removeCookie, setCookie]);

    return (
        <div className="container page">
            <Seo title="Politique de confidentialité et Protection de la vie privée" />
            <Typography.Title level={1}>Politique de confidentialité et Protection de la vie privée</Typography.Title>
            <Typography.Title level={2} id="cookies">
                Choix des cookies
            </Typography.Title>

            <Typography.Title level={3}>Optionnels</Typography.Title>
            <ul>
                <li className="flex">
                    <Switch checked={!!cookiesState.gtag} onChange={onToggle.bind(null, 'ga')} />
                    <p>
                        Cookies de Google Analytics destinés à réaliser des statistiques de navigation et de
                        fréquentation
                        <code>ezee-ga, _gat, _gid, _ga</code>
                    </p>
                </li>
            </ul>
            <Typography.Title level={3}>Obligatoires</Typography.Title>
            <ul>
                <li className="flex">
                    <Switch checked disabled />
                    <p>
                        Cookie permettant de déterminer si l&rsquo;utilisateur a accepté ou refusé un ou plusieurs
                        cookies
                        <code>ezee-has_made_choice</code>
                    </p>
                </li>
            </ul>
            <div className="flex">
                <Button type="ghost" onClick={onClickRefuse}>
                    Tout refuser
                </Button>
                <Button type="primary" onClick={onClickAccept}>
                    Tout accepter
                </Button>
                {showSuccess && (
                    <div>
                        <Tag icon={<CheckCircleOutlined />} color="success">
                            Enregistré
                        </Tag>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Privacy;
