import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Alert, Typography } from 'antd';

import { getAuthState, forgottenPassword } from '../../store/actions/auth';
import { LoginPayload } from '../../store/api/auth';

import logo from '../../assets/images/logo.svg';
import Seo from '../../components/Seo';
import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useActions } from '../../hooks';

const ForgottenPassword: FC = () => {
    const [sendForgottenPassword, resetForgottenPassword] = useActions([
        forgottenPassword.trigger,
        forgottenPassword.reset,
    ]);
    const authState = useSelector(getAuthState);
    const [email, setEmail] = useState<string | undefined>();
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        setEmail(values.username);
        sendForgottenPassword(values);
    };
    const requiredRule = { required: true, message: 'champ requis' };

    let error = authState.forgottenPasswordError
        ? 'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard.'
        : null;

    if (authState.forgottenPasswordError?.status && authState.forgottenPasswordError.status === 404) {
        error = 'Adresse e-mail non trouvée.';
    }

    useEffect(
        () => () => {
            setEmail(undefined);
            resetForgottenPassword();
        },
        [setEmail, resetForgottenPassword]
    );

    return (
        <LoginLayout>
            <Seo title="Mot de passe oublié" />
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                <img className="logo" src={logo} alt="logo" />
                <Typography.Title level={1}>Mot de passe oublié</Typography.Title>

                {authState.forgottenPasswordSuccess ? (
                    <Typography.Paragraph>
                        <p>
                            Nous venons de vous envoyer un lien pour réinitialiser votre mot de passe à l’adresse{' '}
                            {email}.<br />
                            <br />
                            Merci de surveiller vos e-mails.
                        </p>
                    </Typography.Paragraph>
                ) : (
                    <>
                        <Typography.Paragraph>
                            <p>
                                Saisissez l’adresse e-mail associée à votre compte, nous vous enverrons un mail avec un
                                lien pour réinitialiser votre mot de passe.
                            </p>
                        </Typography.Paragraph>
                        <Form.Item
                            name="username"
                            rules={[
                                requiredRule,
                                {
                                    type: 'email',
                                    message: 'Adresse e-mail non valide',
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <Input placeholder="Adresse e-mail" />
                        </Form.Item>
                        <Form.Item>
                            {error ? (
                                <div className="login-error-message">
                                    <Alert type="error" message={error} showIcon />
                                </div>
                            ) : null}

                            <Button type="primary" size="large" htmlType="submit" loading={authState.loading} block>
                                Réinitialiser votre mot de passe
                            </Button>
                        </Form.Item>
                    </>
                )}
                <Link to={getRoute(RoutePathName.login)}>Revenir à l’écran de connexion</Link>
            </Form>
        </LoginLayout>
    );
};

export default ForgottenPassword;
