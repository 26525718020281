import React, { FC, useEffect, useState } from 'react';

// Antd lib
import Table, { ColumnProps } from 'antd/lib/table';
import { Button, Col, message, Popconfirm, Row } from 'antd';

import { AppstoreAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

// Store
import { useSelector } from 'react-redux';

import {
    list as listApplicationsAction,
    remove as removeApplicationAction,
    getApplicationsListState,
    getApplicationsCreateState,
    getApplicationsRemoveState,
    getApplicationsUpdateState,
    getApplicationsDetailsState,
} from '../../store/actions/applications';

import {
    remove as removeApplicationsClientAction,
    getApplicationsClientsCreateState,
    getApplicationsClientsUpdateState,
    getApplicationsClientsRemoveState,
} from '../../store/actions/applicationsClients';

// Api Types
import { Application, ApplicationClient } from '../../store/api/apiTypes';

// Helpers
import { useActions, usePrevious } from '../../hooks';

// Components
import Seo from '../../components/Seo';
import ListTitle from '../../components/ListTitle';
import LayoutHeader from '../../components/LayoutHeader';
import ApplicationCreateModal from './components/ApplicationCreateModal';
import ApplicationUpdateModal from './components/ApplicationUpdateModal';
import ApplicationClientCreateModal from './components/ApplicationClientCreateModal';
import ApplicationClientUpdateModal from './components/ApplicationClientUpdateModal';
// Style
import '../../assets/styles/Applications.less';

const Applications: FC = () => {
    const [loadApplications, removeApplications, removeApplicationClient] = useActions([
        listApplicationsAction.trigger,
        removeApplicationAction.trigger,
        removeApplicationsClientAction.trigger,
    ]);
    const listApplicationsState = useSelector(getApplicationsListState);
    const createApplicationsState = useSelector(getApplicationsCreateState);
    const updateApplicationsState = useSelector(getApplicationsUpdateState);
    const removeApplicationsState = useSelector(getApplicationsRemoveState);
    const detailsApplicationsState = useSelector(getApplicationsDetailsState);
    const createApplicationsClientsState = useSelector(getApplicationsClientsCreateState);
    const udpateApplicationsClientsState = useSelector(getApplicationsClientsUpdateState);
    const removeApplicationsClientsState = useSelector(getApplicationsClientsRemoveState);
    const rowKey = (item: Application) => `${item.id}`;

    const previous = usePrevious({
        listApplicationsState,
        createApplicationsState,
        updateApplicationsState,
        removeApplicationsState,
        detailsApplicationsState,
        createApplicationsClientsState,
        udpateApplicationsClientsState,
        removeApplicationsClientsState,
    });

    const [isVisibleApplicationCreateModal, setIsVisibleApplicationCreateModal] = useState<boolean>(false);
    const [isVisibleApplicationUpdateModal, setIsVisibleApplicationUpdateModal] = useState<boolean>(false);
    const [isVisibleApplicationClientsCreateModal, setIsVisibleApplicationClientsCreateModal] = useState<boolean>(
        false
    );
    const [isVisibleApplicationClientsUpdateModal, setIsVisibleApplicationClientsUpdateModal] = useState<boolean>(
        false
    );
    const [applicationId, setApplicationId] = useState<Application['id']>('');
    const [applicationClientId, setApplicationClientId] = useState<ApplicationClient['id']>('');

    const createApplicationModalVisible = () => {
        setIsVisibleApplicationCreateModal(true);
    };

    // On Click Action For Application
    const onClickRemoveApplicationButton = (id: Application['id']) => {
        removeApplications({ id });
    };

    const onClickUpdateApplicationButton = (id: Application['id']) => {
        setApplicationId(id);
        setIsVisibleApplicationUpdateModal(true);
    };

    const onClickAddApplicationClientButton = (id: Application['id']) => {
        setApplicationId(id);
        setIsVisibleApplicationClientsCreateModal(true);
    };

    // On Click Action For Application Cient
    const onClickUpdateApplicationClientButton = (id: ApplicationClient['id'], application: Application['id']) => {
        setApplicationId(application);
        setApplicationClientId(id);
        setIsVisibleApplicationClientsUpdateModal(true);
    };

    const onClickRemoveApplicationClientButton = (id: ApplicationClient['id'], application: Application['id']) => {
        removeApplicationClient({
            id,
            application,
        });
    };

    const columns: Array<ColumnProps<Application>> = [
        {
            title: "Nom de l'application",
            dataIndex: 'name',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: '15%',
            render: (id: string, record: Application) => {
                const isRemoveButtonDisabled = record.applicationClients?.length !== 0;
                const removeButton = (
                    <Popconfirm
                        placement="topLeft"
                        title={'Êtes-vous sûr de supprimer cette application ?'}
                        onConfirm={onClickRemoveApplicationButton.bind(null, id)}
                        okText="Oui"
                        cancelText="Non"
                        disabled={isRemoveButtonDisabled}
                    >
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            disabled={isRemoveButtonDisabled}
                        />
                    </Popconfirm>
                );
                return (
                    <>
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<AppstoreAddOutlined />}
                            onClick={onClickAddApplicationClientButton.bind(null, id)}
                        />
                        <Button
                            className="actions-buttons"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={onClickUpdateApplicationButton.bind(null, id)}
                        />

                        {removeButton}
                    </>
                );
            },
        },
    ];

    const expandedRowRender = (record: Application) => {
        const subColumns: Array<ColumnProps<ApplicationClient>> = [
            {
                title: "Nom de l'aplication client",
                dataIndex: 'name',
            },
            {
                title: 'API Key',
                dataIndex: 'apiKey',
            },
            {
                title: "Type d'appareil",
                dataIndex: 'type',
            },
            {
                title: 'Actions',
                dataIndex: 'id',
                width: '15%',
                render: (id: string) => {
                    return (
                        <>
                            <Button
                                className="actions-buttons"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={onClickUpdateApplicationClientButton.bind(null, id, record.id)}
                            />
                            <Popconfirm
                                placement="topLeft"
                                title={'Êtes-vous sûr de supprimer cette application client ?'}
                                onConfirm={onClickRemoveApplicationClientButton.bind(null, id, record.id)}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button className="actions-buttons" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </>
                    );
                },
            },
        ];

        const expandedRow = (
            <Table columns={subColumns} dataSource={record.applicationClients} pagination={false} rowKey={'id'} />
        );
        return expandedRow;
    };

    // Modals Actions
    const hideComponents = () => {
        setIsVisibleApplicationCreateModal(false);
        setIsVisibleApplicationUpdateModal(false);
        setIsVisibleApplicationClientsCreateModal(false);
        setIsVisibleApplicationClientsUpdateModal(false);
    };

    // Use Effect action

    // Load application list when create application
    useEffect(() => {
        if (previous?.createApplicationsState.loading && !createApplicationsState.loading) {
            if (createApplicationsState.success) {
                loadApplications();
            }
        }
    }, [previous, createApplicationsState, loadApplications]);

    // Load application list when update application
    useEffect(() => {
        if (previous?.updateApplicationsState.loading && !updateApplicationsState.loading) {
            if (updateApplicationsState.success) {
                loadApplications();
            }
        }
    }, [previous, updateApplicationsState, loadApplications]);

    // Load application list when remove application
    useEffect(() => {
        if (previous?.removeApplicationsState.loading && !removeApplicationsState.loading) {
            if (removeApplicationsState.success) {
                message.success("L'application supprimée");
            }
            loadApplications();
        }
    }, [previous, removeApplicationsState, loadApplications]);

    // Application Client Creation State
    useEffect(() => {
        if (previous?.createApplicationsClientsState.loading && !createApplicationsClientsState.loading) {
            if (createApplicationsClientsState.success) {
                loadApplications();
            }
        }
    }, [previous, createApplicationsClientsState, loadApplications]);

    // Application Client Update State
    useEffect(() => {
        if (previous?.udpateApplicationsClientsState.loading && !udpateApplicationsClientsState.loading) {
            if (udpateApplicationsClientsState.success) {
                loadApplications();
            }
        }
    }, [previous, udpateApplicationsClientsState, loadApplications]);

    // Load application list when remove application
    useEffect(() => {
        if (previous?.removeApplicationsClientsState.loading && !removeApplicationsClientsState.loading) {
            if (removeApplicationsClientsState.success) {
                message.success("L'application client supprimée");
            }
            loadApplications();
        }
    }, [previous, removeApplicationsClientsState, loadApplications]);

    // Laod application list in startup
    useEffect(() => {
        loadApplications();
    }, [loadApplications]);

    return (
        <>
            <Seo title="Applications" />
            <LayoutHeader>
                <Row>
                    <Col span={12}>
                        <ListTitle count={listApplicationsState.data?.totalCount}>Applications</ListTitle>
                    </Col>
                    <Col span={12}>
                        <Button
                            className="header-button"
                            size="large"
                            type="primary"
                            onClick={createApplicationModalVisible}
                        >
                            Ajouter une application
                        </Button>
                    </Col>
                </Row>
            </LayoutHeader>

            <Table<Application>
                className="applications-list"
                columns={columns}
                rowKey={rowKey}
                loading={listApplicationsState.loading}
                dataSource={listApplicationsState.data?.items}
                pagination={false}
                expandable={{ expandedRowRender }}
                // onChange={onTableChange}
            />

            <ApplicationCreateModal
                isComponentVisible={isVisibleApplicationCreateModal}
                hideComponent={hideComponents}
            />

            <ApplicationUpdateModal
                applicationId={applicationId}
                isComponentVisible={isVisibleApplicationUpdateModal}
                hideComponent={hideComponents}
            />

            <ApplicationClientCreateModal
                applicationId={applicationId}
                isComponentVisible={isVisibleApplicationClientsCreateModal}
                hideComponent={hideComponents}
            />

            <ApplicationClientUpdateModal
                applicationId={applicationId}
                applicationClientId={applicationClientId}
                isComponentVisible={isVisibleApplicationClientsUpdateModal}
                hideComponent={hideComponents}
            />
        </>
    );
};

export default Applications;
