import api from './_client';

// Types
import { Application, ApplicationClient } from './apiTypes';

// Controller Interface
export interface ApplicationClientCreatePayload {
    body: {
        name: ApplicationClient['name'];
        reference: ApplicationClient['reference'];
        type: ApplicationClient['type'];
        iosOptions: ApplicationClient['iosOptions'];
        androidOptions: ApplicationClient['androidOptions'];
        option: ApplicationClient['options'];
        application: ApplicationClient['application'];
    };
}

export interface ApplcationClientUpdatePayload {
    id: ApplicationClient['id'];
    application: ApplicationClient['application'];
    body: {
        name: ApplicationClient['name'];
        iosOptions: ApplicationClient['iosOptions'];
        androidOptions: ApplicationClient['androidOptions'];
        option: ApplicationClient['options'];
    };
}

export interface ApplicationClientIdPayload {
    id: ApplicationClient['id'];
    application: Application['id'];
}

// Routes
export const create = async (payload: ApplicationClientCreatePayload) => {
    return await api.post(`/applications/${(payload.body.application as unknown) as string}/clients`, payload.body);
};

export const update = async (payload: ApplcationClientUpdatePayload) => {
    return await api.put(
        `/applications/${(payload.application as unknown) as string}/clients/${payload.id}`,
        payload.body
    );
};

export const details = async (payload: ApplicationClientIdPayload) => {
    return await api.get(`/applications/${payload.application}/clients/${payload.id}`);
};

export const remove = async (payload: ApplicationClientIdPayload) => {
    return await api.delete(`/applications/${payload.application}/clients/${payload.id}`);
};
