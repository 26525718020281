import { fork } from 'redux-saga/effects';

import { authSaga } from '../actions/auth';
import { logsSaga } from '../actions/logs';
import { usersSaga } from '../actions/users';
import { applicationsSaga } from '../actions/applications';
import { organizationsSaga } from '../actions/organizations';
import { applicationsClientsSaga } from '../actions/applicationsClients';
import { recipientsSaga } from '../actions/recipients';
import { notificationsSaga } from '../actions/notification';
import { eventSaga } from '../actions/event';
import { devicesSaga } from '../actions/devices';

export default function* mainSaga() {
    yield fork(authSaga);
    yield fork(logsSaga);
    yield fork(usersSaga);
    yield fork(applicationsSaga);
    yield fork(organizationsSaga);
    yield fork(recipientsSaga);
    yield fork(applicationsClientsSaga);
    yield fork(notificationsSaga);
    yield fork(eventSaga);
    yield fork(devicesSaga);
}
