import React, { FC, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Antd lib
import { SiderProps } from 'antd/lib/layout';
import { Layout, Menu, Typography } from 'antd';
import { ApartmentOutlined, AppstoreOutlined, BellOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';

// Helpers
import { constants } from '../helpers';
import { getRoute, RoutePathName } from '../routes';
import { LayoutContext } from '../context/LayoutContext';

// Styles
import '../assets/styles/MainMenu.less';

// Assets
import logo from '../assets/images/logo.svg';

const Sidebar: FC = () => {
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useContext(LayoutContext);
    const location = useLocation();
    const onCollapse: SiderProps['onCollapse'] = (value) => {
        setIsSidebarCollapsed(value);
    };

    return (
        <Layout.Sider
            collapsible
            collapsed={isSidebarCollapsed}
            onCollapse={onCollapse}
            trigger={null}
            breakpoint="lg"
            theme="light"
            id="sidebar"
        >
            <div id="sidebar-wrapper">
                <div>
                    <div id="logo-wrapper">
                        <Link to={getRoute(RoutePathName.home)}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <Menu mode="inline" defaultSelectedKeys={[location.pathname]} defaultOpenKeys={['sub1']}>
                        <Menu.Item key={getRoute(RoutePathName.home)}>
                            <Link to={getRoute(RoutePathName.home)}>
                                <HomeOutlined />
                                <span>Home</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key={getRoute(RoutePathName.usersList)}>
                            <Link to={getRoute(RoutePathName.usersList)}>
                                <UserOutlined />
                                <span>Users</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key={getRoute(RoutePathName.organizations)}>
                            <Link to={getRoute(RoutePathName.organizations)}>
                                <ApartmentOutlined />
                                <span>Organisations</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key={getRoute(RoutePathName.applications)}>
                            <Link to={getRoute(RoutePathName.applications)}>
                                <AppstoreOutlined />
                                <span>Applications</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key={getRoute(RoutePathName.recipientsList)}>
                            <Link to={getRoute(RoutePathName.recipientsList)}>
                                <BellOutlined />
                                <span>Recipients</span>
                            </Link>
                        </Menu.Item>

                        {/* <Menu.Item key={getRoute(RoutePathName.notifications)}>
                            <Link to={getRoute(RoutePathName.notifications)}>
                                <BellOutlined />
                                <span>Notifications</span>
                            </Link>
    </Menu.Item> */}
                    </Menu>
                </div>
                <Typography.Text type="secondary">v{constants.VERSION}</Typography.Text>
            </div>
        </Layout.Sider>
    );
};

export default Sidebar;
