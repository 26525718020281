import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Button, Alert, Typography, Spin } from 'antd';

import { getAuthState, resetPassword, resendInvitation as resendInvitationAction } from '../../store/actions/auth';
import { LoginPayload } from '../../store/api/auth';

import logo from '../../assets/images/logo.svg';
import Seo from '../../components/Seo';
import PasswordInput from '../../components/PasswordInput';
import LoginLayout from '../../components/LoginLayout';
import ButtonLink from '../../components/ButtonLink';
import { checkIfTokenExpired } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import useQueryParams from '../../hooks/queryParams';
import validatePasswordRules from '../../helpers/passwords';
import { useActions } from '../../hooks';

const ResetPassword: FC = () => {
    const [sendResetPassword, resetResetPassword, resendInvitation] = useActions([
        resetPassword.trigger,
        resetPassword.reset,
        resendInvitationAction.trigger,
    ]);
    const authState = useSelector(getAuthState);
    const [isTokenExpired, setIsTokenExpired] = useState<boolean | null>(null);
    const [queryParams] = useQueryParams('reset-password');
    const [form] = Form.useForm();
    const [, forceUpdate] = useState(false);
    const token = queryParams.get('token');
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        sendResetPassword({
            ...values,
            token,
        });
    };
    const onClickSendNewInvitation = () => {
        resendInvitation({ token });
    };

    let error =
        authState.resetPasswordError || authState.resendInvitationError
            ? 'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard.'
            : null;

    if (authState.resetPasswordError) {
        if (authState.resetPasswordError?.status === 404) {
            error = 'Adresse e-mail non trouvée.';
        } else if (authState.resetPasswordError?.status === 400) {
            error = 'Mot de passe non valide';
        } else {
            error = 'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard.';
        }
    }

    const formContent = authState.resetPasswordSuccess ? (
        <>
            <Typography.Paragraph>Votre mot de passe a été modifié avec succès</Typography.Paragraph>
            <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
                Connexion
            </ButtonLink>
        </>
    ) : (
        <>
            <Typography.Paragraph>
                <p>Merci de saisir un nouveau mot de passe ci-dessous</p>
            </Typography.Paragraph>
            <Form.Item
                label="Nouveau mot de passe"
                rules={[
                    { required: true, message: 'Veuillez renseigner votre nouveau mot de passe' },
                    {
                        validator: async (_, value) => {
                            if (!value || !validatePasswordRules(value)) {
                                return await Promise.resolve();
                            }
                            return await Promise.reject(new Error('Mot de passe non valide'));
                        },
                    },
                ]}
                name="password"
            >
                <PasswordInput />
            </Form.Item>
            {error ? (
                <Form.Item>
                    <Alert type="error" message={error} showIcon />
                </Form.Item>
            ) : null}
            <Form.Item shouldUpdate>
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={authState.loading}
                        size="large"
                        disabled={
                            !form.isFieldsTouched() ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                        block
                    >
                        Réinitialiser votre mot de passe
                    </Button>
                )}
            </Form.Item>
        </>
    );

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (token) {
            setIsTokenExpired(token === null ? true : checkIfTokenExpired(token));
        }
    }, [setIsTokenExpired, token]);

    useEffect(
        () => () => {
            resetResetPassword();
        },
        [resetResetPassword]
    );

    return (
        <LoginLayout>
            <Seo title="Réinitialisation du mot de passe" />
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                <img className="logo" src={logo} alt="logo" />

                {isTokenExpired === null ? ( // checking token expiration
                    <Spin />
                ) : isTokenExpired ? (
                    <>
                        {authState.resendInvitationSuccess ? (
                            <Typography.Paragraph>
                                <p>Vous allez recevoir une nouvelle invitation par email</p>
                            </Typography.Paragraph>
                        ) : (
                            <>
                                <div className="login-error-message" style={{ padding: '2rem 0' }}>
                                    <Alert type="error" message={error ?? "L'invitation a expiré."} />
                                </div>
                                <Button onClick={onClickSendNewInvitation} type="primary" size="large">
                                    Renvoyer une invitation
                                </Button>
                            </>
                        )}
                    </>
                ) : (
                    formContent
                )}
            </Form>
        </LoginLayout>
    );
};

export default ResetPassword;
