import api from './_client';

export interface LogsPayload {
    streams: Array<{
        stream: {
            app: 'holirenting';
            partner: string;
        };
        values: Array<[string, string]>;
    }>;
}

export const logs = async (payload: LogsPayload) =>
    await api.post('/debug/logs', payload, {
        headers: {
            'content-type': 'application/json',
        },
        baseURL: '/',
    });
